<template>
  <div class="list">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="main">
      <van-cell is-link title="点击选择分类" @click="showSelect = true" />

      <div class="classTitle">
        当前分类: <span>{{ currentItem.name }}</span>
      </div>
      <div class="main">
        <van-button style="width: 100%" type="info" @click="opDetail(1)"
          >添加商品</van-button
        >
        <div class="item" v-for="(v, i) in list" :key="i">
          <span>{{ v.title }}</span>
          <div class="btns">
            <van-button
              type="danger"
              style="margin-right: 8px"
              @click="delShopBtn(v.id)"
              >删除</van-button
            >
            <van-button type="primary" @click="opDetail(2, v)">编辑</van-button>
          </div>
        </div>
      </div>
      <van-action-sheet
        v-model="showSelect"
        :actions="classList"
        @select="onSelect"
      />
    </div>
  </div>
</template>

<script>
import { getClass, getList, delShop } from "@/api/com";
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      list: [],
      classList: [],
      showSelect: false,
      currentItem: {},
    };
  },
  created() {
    this.getClassList();
  },
  methods: {
    delShopBtn(id) {
      Dialog.confirm({
        title: "确认删除?",
      })
        .then(async () => {
          let res = await delShop(id);
          if (res.code == 0) {
            Toast("删除成功");
            this.getDataList(this.currentItem.id);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    opDetail(type, val) {
      if (type == 1) {
        this.$router.push({
          path: "/detail",
          query: {
            classId: this.currentItem.id,
            className: this.currentItem.name,
          },
        });
      } else {
        this.$router.push({
          path: "/detail",
          query: {
            classId: this.currentItem.id,
            className: this.currentItem.name,
            editId: val.id,
          },
        });
      }
    },
    onSelect(item) {
      this.showSelect = false;
      this.currentItem = item;
      this.getDataList(item.id)
    },
    async getClassList() {
      let res = await getClass();
      let arr = [];
      res.data.map((v) => {
        arr.push({
          name: v.className,
          id: v.id,
        });
      });
      this.currentItem = arr[0];
      this.classList = arr;
      this.getDataList(arr[0].id);
    },
    onClickLeft() {
      this.$router.push("/");
    },
    async getDataList(id) {
      if (!id) return;
      let res = await getList(id);
      this.list = res.data;
    },
  },
};
</script>

<style scoped>
.classTitle {
  padding: 20px;
}
.classTitle span {
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
}
.item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
}
</style>